import * as React from 'react'
import AboutHeader from '../components/about_header'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Link from '../components/link'
import Container from '../components/container'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const PressPage = ({ data }) => {

  const content = data.contentfulPage
  const items = data.allContentfulPressItem.nodes

  return (
    <Layout>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.subtitle}
      />
      <AboutHeader
        title={content.title}
        subtitle={content.subtitle}
        isPress={true}
      />
      <div className='Section Section--condensed'>
        <Container size='lg'>
          <div className='grid gap-2 sm:grid-cols-2 md:grid-cols-3'>

            {items.map(item =>
              <a
                key={`press_item_${item.url}`}
                href={item.url}
                target='_blank'
                rel='noopener noreferrer'
                className='font-semi-bold p-3 pr-4 w-full flex flex-col border border-mid-gray hover:border-black'
              >
                <div className='font-semi-bold text-lg leading-relaxed mb-1 text-gray'>
                  {item.title}
                </div>
                <div className='text-silver mb-2 leading-relaxed font-semi-bold'>
                  {item.date}
                </div>
                <Link
                  className='uppercase'
                  children={
                    item.type === 'Video' ? 'Watch now' :
                    item.type === 'Audio' ? 'Listen now' :
                    'Read more'
                  }
                  arrowDirection='right'
                />
                {item.logo &&
                  <div className='mt-auto pt-3'>
                    <GatsbyImage
                      image={item.logo.gatsbyImageData}
                      alt=''
                    />
                  </div>
                }
              </a>
            )}

          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default PressPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "press"}) {
      title
      subtitle
      metaDescription
      seoTitle
    }
    allContentfulPressItem(sort: {fields: date, order: DESC}) {
      nodes {
        title
        date(formatString: "MM/DD/YYYY")
        url
        type
        logo {
          gatsbyImageData(placeholder: TRACED_SVG, height: 58)
        }
      }
    }
  }
`
